/**
* (Stepped) colors & their palettes go in here.
*
* Note: must be kept in-sync with the color config for Tailwind
* in tailwind-colors.js.
*/

@use "@angular/material" as mat;
@forward "@angular/material" show get-color-from-palette, get-contrast-color-from-palette;

/// Shortcut/alias for get-color-from-palette() from @angular/material
///
/// @param {Map} $palette - The palette to select the hue & (optionally) opacity from
/// @returns {Color} - The color from the palette
@function color($palette, $hue: default, $opacity: 1) {
  @return rgba(mat.get-color-from-palette($palette, $hue), $opacity);
}

$text-dark: #494949;
$text-bright: #fff;

$-gray-steps: (
  50: #fefefe,
  100: #e9e9e9,
  200: #d4d4d4,
  300: #aaa,
  400: #959595,
  500: #808080,
  600: #707070,
  700: #606060,
  800: #505050,
  900: #404040,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-bright,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$gray: mat.define-palette($-gray-steps);
$grey: $gray;

$-blue-steps: (
  50: #eff8fd,
  100: #e6f3fc,
  200: #b3dcf5,
  300: #66b9ea,
  400: #33a2e3,
  500: #008bdc,
  600: #006fb0,
  700: #005384,
  800: #003858,
  900: #001c2c,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-bright,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$blue: mat.define-palette($-blue-steps);

$-indigo-steps: (
  50: #f1ebf5,
  100: #ebe6f2,
  200: #af9ccc,
  300: #7252a6,
  400: #5e3999,
  500: #360880,
  600: #310773,
  700: #2b0666,
  800: #20054d,
  900: #160333,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-bright,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$indigo: mat.define-palette($-indigo-steps);

$-orange-steps: (
  50: #fff5ef,
  100: #fff0e6,
  200: #ffd1b3,
  300: #ffa366,
  400: #ff8533,
  500: #f60,
  600: #cc5200,
  700: #993d00,
  800: #662900,
  900: #331400,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-bright,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$orange: mat.define-palette($-orange-steps);

$-pink-steps: (
  50: #fff7fe,
  100: #fff7fe,
  200: #ffe8fb,
  300: #ffd0f7,
  400: #ffc1f4,
  500: #ffb1f1,
  600: #cc8ec1,
  700: #996a91,
  800: #664760,
  900: #332330,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-dark,
    400: $text-dark,
    500: $text-dark,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$pink: mat.define-palette($-pink-steps);

$-azure-steps: (
  50: #f0fdff,
  100: #e6fafd,
  200: #b3f1f8,
  300: #80e7f4,
  400: #33d9ed,
  500: #00cfe9,
  600: #00a6ba,
  700: #006875,
  800: #003e46,
  900: #001c2c,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-dark,
    400: $text-dark,
    500: #332330,
    600: #332330,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$azure: mat.define-palette($-azure-steps);

$-error-red-steps: (
  50: #fbe7e7,
  100: #fbe7e7,
  200: #ef9f9f,
  300: #e86e6e,
  400: #e03e3e,
  500: #d80e0e,
  600: #ad0b0b,
  700: #820808,
  800: #6c0707,
  900: #2b0303,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-bright,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$error-red: mat.define-palette($-error-red-steps);

$-alert-yellow-steps: (
  50: #fffdf3,
  100: #fef9e6,
  200: #fbe69d,
  300: #f9da6b,
  400: #f7cd3a,
  500: #f5c109,
  600: #ddae08,
  700: #ac8706,
  800: #7b6105,
  900: #493a03,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-dark,
    400: $text-dark,
    500: $text-dark,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$alert-yellow: mat.define-palette($-alert-yellow-steps);

$-success-green-steps: (
  50: #effcf3,
  100: #e7f7ec,
  200: #a1e0b3,
  300: #71d08c,
  400: #42c166,
  500: #13b140,
  600: #0f8e33,
  700: #0b6a26,
  800: #08471a,
  900: #04230d,
  contrast: (
    50: $text-dark,
    100: $text-dark,
    200: $text-dark,
    300: $text-dark,
    400: $text-bright,
    500: $text-bright,
    600: $text-bright,
    700: $text-bright,
    800: $text-bright,
    900: $text-bright,
  )
);
$success-green: mat.define-palette($-success-green-steps);

$warn-palette: $error-red;

$default-primary-palette: $blue;
