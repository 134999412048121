@use "sass:map";
@use "../palettes";
@use "../shadows";
@use "@angular/material" as mat;
@use "../typography" as typo;

div.mat-menu-panel {
  background: white;
  border-radius: 2px;
  max-width: 80vw;

  // 1.9px is weirdly needed in Chrome/Edge instead of 2px
  border: 1.9px solid palettes.color(palettes.$blue, 500);

  @include shadows.box-shadow(blue);

  .mat-menu-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-width: max-content;

    &:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  min-height: 0 !important;
  margin-top: 4px;

  // style the case when the popup opens up instead of down, where the box-shadow overlays the button
  &.mat-menu-above {
    box-shadow: none;
    margin-bottom: 4px;
  }
}

div.mat-menu-panel .mat-menu-content > .mat-menu-item {
  height: 40px;
  line-height: 40px;
  width: 100%;

  border-bottom: 1px solid palettes.color(palettes.$gray, 100);
  padding: 0 8px;

  color: palettes.color(palettes.$gray, 800);

  &:disabled {
    color: palettes.color(palettes.$gray, 300) !important;
    background-color: palettes.color(palettes.$gray, 100) !important;
  }

  &:last-child {
    border-bottom: 0;
  }

  background: white;
  display: flex;
  align-items: center;
  gap: 8px;

  &:visited:not([disabled]),
  &:hover:not([disabled]) {
    color: inherit;
    text-decoration: none;
  }

  &:hover:not([disabled]),
  &:focus:not(.cdk-mouse-focused):not([disabled]) {
    background: palettes.color(palettes.$blue, 100);
    color: palettes.color(palettes.$gray, 600);
  }

  &:active:active:not([disabled]) {
    background: palettes.color(palettes.$blue, 200);
    color: palettes.color(palettes.$gray, 800);
  }
}
