@use "../palettes";

@mixin color($primary, $neutral) {
  /** Size adjustments **/
  &.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.666);
  }

  .mat-ripple-element {
    display: none;
  }

  // Default
  .mat-radio-outer-circle {
    border-width: 1px;
    border-color: palettes.color($neutral, 500);
  }

  // Hover
  .mat-radio-container:hover {
    .mat-radio-outer-circle {
      background-color: palettes.color($neutral, 100);
    }
  }

  // Pressed
  .mat-radio-container:active {
    .mat-radio-outer-circle {
      border-color: palettes.color($neutral, 700);
      background-color: palettes.color($neutral, 200);
    }
  }

  // Checked
  &.mat-radio-checked {
    // Hover
    .mat-radio-container:hover {
      .mat-radio-outer-circle {
        border-color: palettes.color($primary, 400);
        background-color: palettes.color($primary, 100);
      }

      .mat-radio-inner-circle {
        background-color: palettes.color($primary, 400);
      }
    }

    // Pressed
    .mat-radio-container:active {
      .mat-radio-outer-circle {
        border-color: palettes.color($primary, 400);
        background-color: palettes.color($primary, 200);
      }

      .mat-radio-inner-circle {
        background-color: palettes.color($primary, 400);
      }
    }
  }
}

mat-radio-button.mat-radio-button.mat-primary {
  @include color(palettes.$indigo, palettes.$gray);
}

mat-radio-button.mat-radio-button.mat-accent {
  @include color(palettes.$orange, palettes.$gray);
}

mat-radio-button.mat-radio-button.mat-warn {
  @include color(palettes.$error-red, palettes.$gray);
}

mat-radio-group.mat-radio-group {
  display: flex;
  gap: 0 24px;

  &.vertical {
    flex-direction: column;
  }
}
