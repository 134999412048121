@use "sass:map";
@use "@angular/material" as mat;
@use "../typography" as typo;
@use "../palettes" as pal;

$active-tab-label-bg-color: pal.color(pal.$indigo, 100);
$tab-label-text-color: pal.color(pal.$indigo);

@mixin -color($config) {
  mat-tab-group {
    & .mat-tab-label {
      border-bottom: 2px solid $tab-label-text-color;
      color: $tab-label-text-color;
      opacity: 1;
    }

    & .mat-tab-label.hidden {
      display: none;
    }

    & .mat-tab-label-active {
      background: $active-tab-label-bg-color;
    }

    & .mat-ink-bar {
      display: none;
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color {
    @include -color($color);
  }
}
