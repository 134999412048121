@use "@angular/material" as mat;
@use "/src/theme/theme-definitions" as themes;
@use "/src/theme/palettes";
@use "/src/theme/shadows";

mat-button-toggle-group.mat-button-toggle-group {
  border-radius: 4px;
  overflow: visible;

  &.mat-button-toggle-group-icon .mat-button-toggle-label-content {
    line-height: 0;
    font-size: 30px;
    padding: 0 4px;

    ion-icon {
      font-size: inherit;
    }
  }

  &.mat-button-toggle-group-large .mat-button-toggle-label-content {
    line-height: 24px;
    padding: 9px 32px;
    font-size: 20px;
    font-weight: 600;
  }

  mat-button-toggle.mat-button-toggle {
    display: inline-block;
    margin-right: -1px;
  }

  button {
    width: inherit;
  }

  @mixin color($palette) {
    border: 0 solid palettes.color($palette, 300);

    mat-button-toggle.mat-button-toggle.mat-button-toggle-appearance-standard {
      color: palettes.color($palette, 400);
      background: white;
      border-color: palettes.color($palette, 300);
      border-width: 1px 0 1px 1px;
      border-style: solid;

      .mat-ripple,
      .mat-button-toggle-focus-overlay {
        display: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-right-width: 1px;
        border-radius: 0 4px 4px 0;
      }

      &:not(.mat-button-toggle-disabled) {
        &:hover {
          background: palettes.color($palette, 100);
        }

        &:active {
          background: palettes.color($palette, 100);

          &:hover {
            background: palettes.color($palette, 100);
          }
        }
      }

      &.mat-button-toggle-checked {
        background: palettes.color($palette, 100);
      }
    }
  }

  // default color schema
  @include color(palettes.$gray);

  &.mat-primary {
    @include color(palettes.$indigo);
  }

  &.mat-accent {
    @include color(palettes.$gray);
  }

  &.mat-warn {
    @include color(palettes.$error-red);
  }

  &.mat-error {
    @include color(palettes.$error-red);
  }

  &.mat-alert {
    @include color(palettes.$alert-yellow);
  }

  &.mat-success {
    @include color(palettes.$success-green);
  }
}
