@use "sass:map";
@use "sass:meta";
@use "@angular/material";

/**
* Obtain color from a theme's color configuration
*/
@function get-color-from-theme($theme, $palette: primary, $color: default, $opacity: null) {
  $config: material.get-color-config($theme);

  $palette: map.get($config, $palette);

  $col: material.get-color-from-palette($palette, $color, $opacity);

  @return $col;
}

/**
* Merge map into theme and theme's color config.
*/
@function merge-color-part-into-theme-compat($theme, $type, $source) {
  @if meta.type-of($theme) != map or not map.has-key($theme, color) {
    @error "No theme provided";
  }

  @if meta.type-of($source) != map and meta.type-of($source) != list {
    @error "$source has to be a map";
  }

  //// Merge into color config first

  $color-config: map.get($theme, color);

  $config-property: map.get($color-config, $type);

  @if not $config-property {
    $color-config: map.set($config, $type, $source);
  } @else {
    $config-property: map.deep-merge($config-property, $source);
    $color-config: map.set($color-config, $type, $config-property);
  }

  $theme: map.set($theme, color, $color-config);

  //// Merge at theme-level for backwards-compatibility

  @return map.merge($theme, $type, $source);
}
