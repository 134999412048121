@use "sass:map";
@use "@angular/material" as mat;
@forward "@angular/material" show font-weight, font-size, font-family, typography-level;

$-typography: mat.define-legacy-typography-config(
  $font-family: '"Open Sans", Arial, "Helvetica Neue", sans-serif',
  $headline:      mat.define-typography-level(34px, $line-height: 42px, $font-weight: 700),
  $title:         mat.define-typography-level(28px, $line-height: 36px, $font-weight: 400),
  $subheading-2:  mat.define-typography-level(24px, $line-height: 32px, $font-weight: 400),
  $subheading-1:  mat.define-typography-level(20px, $line-height: 24px, $font-weight: 550),
  $body-2:        mat.define-typography-level(16px, $line-height: 24px, $font-weight: 700),
  $body-1:        mat.define-typography-level(16px, $line-height: 24px, $font-weight: 400),
  $caption:       mat.define-typography-level(12px, $line-height: 24px, $font-weight: 550),
  $button:        mat.define-typography-level(16px, $line-height: 24px, $font-weight: 400)
);

$-level-small: mat.define-typography-level(
  $font-family: mat.font-family($-typography),
  $font-size: 12px,
  $line-height: 24px,
  $font-weight: 400
);

$-level-regular: mat.define-typography-level(
  $font-family: mat.font-family($-typography),
  $font-size: 13.33px,
  $line-height: 24px,
  $font-weight: 400
);

$-typography-with-small: map.merge($-typography, (small: $-level-small));
$typography: map.merge($-typography-with-small, (regular: $-level-regular));

@mixin section-headings($typography) {
  h1 {
    @include mat.typography-level($typography, headline);
  }

  h2 {
    @include mat.typography-level($typography, title);
  }

  h3 {
    @include mat.typography-level($typography, subheading-2);
  }

  h4 {
    @include mat.typography-level($typography, subheading-1);
  }

  h5 {
    font-size: 18px;
    line-height: 32px;
  }

  h6 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600 !important;
  }
}

@mixin typography-classes() {
  .typo-body-2 {
    @include mat.typography-level($typography, body-2);
  }

  .typo-caption {
    @include mat.typography-level($typography, caption);
  }
}
