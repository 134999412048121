@use "sass:map";
@use "@angular/material" as mat;
@use "../palettes";
@use "../shadows";
@use "../theme-definitions" as themes;
@use "../typography";

$outline-border-radius: 2px;
$outine-border-color-focused: palettes.color(palettes.$blue, 500);

mat-form-field.mat-form-field-appearance-outline:not(.mat-form-field-appearance-legacy) {
  .mat-form-field-outline {
    color: palettes.color(palettes.$gray, 300);

    * {
      background:
        themes.get-color-from-theme(
          themes.$default-theme,
          background,
          background
        );
    }
  }

  .mat-form-field-outline-start {
    border-radius: $outline-border-radius 0 0 $outline-border-radius;
    border-right-style: none;

    [dir="rtl"] & {
      border-right-style: solid;
      border-left-style: none;
      border-radius: 0 $outline-border-radius $outline-border-radius 0;
    }
  }

  .mat-form-field-outline-end {
    border-radius: 0 $outline-border-radius $outline-border-radius 0;
    border-left-style: none;
    flex-grow: 1;

    [dir="rtl"] & {
      border-left-style: solid;
      border-right-style: none;
      border-radius: $outline-border-radius 0 0 $outline-border-radius;
    }
  }

  &.mat-focused .mat-form-field-outline-start {
    border-top-color: $outine-border-color-focused;
    border-bottom-color: $outine-border-color-focused;
    border-left-color: $outine-border-color-focused;
  }

  &.mat-focused .mat-form-field-outline-end {
    border-top-color: $outine-border-color-focused;
    border-bottom-color: $outine-border-color-focused;
    border-right-color: $outine-border-color-focused;
  }

  .mat-form-field-outline-gap {
    display: none;
  }

  .mat-form-field-outline-thick {
    @include shadows.box-shadow(2);
    color: palettes.color(palettes.$gray, 300);
  }

  &.mat-focused .mat-form-field-outline-thick {
    @include shadows.box-shadow(blue);
  }

  &.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: palettes.color(palettes.$error-red, 500);
  }

  .mat-form-field-infix {
    padding: 2px 0 9px;
    display: inherit;
  }

  // label positioning
  &.mat-form-field-has-label .mat-form-field-wrapper {
    margin: 1.5em 0 0.25em;
  }

  // prefix/suffix positioning
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0;
    align-self: center;

    .mat-icon-button {
      width: min-content;
    }
  }

  .mat-form-field-label-wrapper {
    top: -3.7em;
    left: -0.75em;
    padding-top: 24px;

    label {
      transform: none !important;
      color: palettes.color(palettes.$gray, 800);

      mat-label {
        @include mat.typography-level(typography.$typography, body-1);
      }
    }
  }

  &.mat-form-field-disabled .mat-form-field-label {
    color: palettes.color(palettes.$gray, 800);
  }

  .mat-input-element:disabled {
    color: palettes.color(palettes.$gray, 200);
  }

  .mat-form-field-prefix ion-icon {
    font-size: 150%;
    margin-inline-end: 0.5em;
    color: palettes.color(palettes.$gray, 300);
  }

  .mat-form-field-suffix ion-icon {
    font-size: 150%;
    color: palettes.color(palettes.$gray, 300);
  }
}

mat-form-field.mat-form-field-appearance-standard:not(.mat-form-field-appearance-legacy).compact {
  .mat-form-field-infix {
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
  }

  .mat-form-field-flex {
    padding: 0;
    align-items: center;
    vertical-align: middle;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  // label positioning
  &.mat-form-field-has-label .mat-form-field-wrapper {
    margin: 1.5em 0 0.25em;
  }

  // prefix/suffix positioning
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: 0;

    ion-icon,
    .mat-icon {
      display: flex;
      align-items: center;
    }

    .mat-icon-button,
    .mdc-icon-button {
      height: 1em;
      width: 1em;
      vertical-align: middle;
      padding: 0;

      svg {
        height: 0.8em;
      }
    }
  }

  .mat-form-field-label-wrapper {
    top: -3.7em;
    left: -0.75em;
    padding-top: 24px;

    label {
      transform: none !important;
      color: palettes.color(palettes.$gray, 800);

      mat-label {
        @include mat.typography-level(typography.$typography, body-1);
      }
    }
  }

  &.mat-form-field-disabled .mat-form-field-label {
    color: palettes.color(palettes.$gray, 800);
  }

  .mat-input-element:disabled {
    color: palettes.color(palettes.$gray, 200);
  }

  .mat-form-field-prefix ion-icon {
    font-size: 150%;
    margin-inline-end: 0.5em;
    color: palettes.color(palettes.$gray, 300);
  }

  .mat-form-field-suffix ion-icon {
    font-size: 150%;
    color: palettes.color(palettes.$gray, 300);
  }
}

mat-form-field.mat-form-field {
  caret-color: palettes.color(palettes.$blue, 400);

  input::placeholder {
    color: palettes.color(palettes.$gray, 300);
    font-style: italic;
  }

  input {
    line-height: 1em;
  }

  .mat-form-field-required-marker {
    color: palettes.color(palettes.$error-red, 500);
  }

  .mat-focused .mat-form-field-required-marker {
    color: palettes.color(palettes.$error-red, 500);
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 4px;

    padding-left: 0;
    padding-right: 0;
  }
}

mat-hint,
mat-error {
  line-height: 20px;
}
