@use '../core/theming/theming';
@use '../core/style/sass-utils';
@use '../core/tokens/token-utils';
@use '../core/tokens/m2/mtx/split' as tokens-mtx-split;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);

  @include sass-utils.current-selector-or-root() {
    @include token-utils.create-token-values(tokens-mtx-split.$prefix,
      tokens-mtx-split.get-color-tokens($config));
  }

  .mtx-split>.mtx-split-gutter {
    &.mat-accent:hover {
      $accent-tokens: tokens-mtx-split.private-get-color-palette-color-tokens($config, accent);
      @include token-utils.create-token-values(tokens-mtx-split.$prefix, $accent-tokens);
    }

    &.mat-warn:hover {
      $warn-tokens: tokens-mtx-split.private-get-color-palette-color-tokens($config, warn);
      @include token-utils.create-token-values(tokens-mtx-split.$prefix, $warn-tokens);
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);

  @include theming.private-check-duplicate-theme-styles($theme, 'mtx-split') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color !=null {
      @include color($color);
    }

    @if $density !=null {
      @include density($density);
    }

    @if $typography !=null {
      @include typography($typography);
    }
  }
}
