@use "../palettes";

.banner {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 10px;

  & > ion-icon {
    flex: 0 0 auto;
  }

  & > div {
    flex: 1;
  }

  &.neutral {
    background-color: palettes.color(palettes.$gray, 100, 0.5);
  }

  &.green {
    background-color: palettes.color(palettes.$success-green, 100, 0.5);
  }

  &.red {
    background-color: palettes.color(palettes.$error-red, 50);
  }
}
