@use "@angular/material" as mat;
@use "../palettes" as pal;
@use "../typography" as typo;

@mixin -structure {
  mat-expansion-panel {
    &.mat-expansion-panel {
      min-width: 70px;
      padding: 16px;

      border-radius: 0 0 4px 4px;

      &:not([class*="mat-elevation-z"]) {
        box-shadow: none;
      }
    }

    .mat-expansion-panel-header {
      padding: 0;

      & mat-panel-title {
        align-items: center;
        gap: 11px;

        h4 {
          margin: 0;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

@mixin -color($config) {
  mat-expansion-panel.mat-expansion-panel {
    border:
      1px solid pal.color(
        pal.$gray,
        300
      );
  }
}

@mixin -typography($config) {
  mat-expansion-panel-header.mat-expansion-panel-header mat-panel-title {
    @include mat.typography-level($config, subheading-2);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include -structure;

  @if $color-config {
    @include -color($color-config);
  }

  @if $typography {
    @include -typography($typography);
  } @else {
    @error "No typography provided to expansion theme";
  }
}
