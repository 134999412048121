@use "sass:map";
@use "@angular/material" as mat;
@use "../palettes" as pal;
@use "../shadows";
@use "../typography" as typo;

@mixin -structure {
  mat-step-header.mat-horizontal-stepper-header {
    height: 32px;
  }

  mat-stepper div.mat-horizontal-stepper-header-container {
    margin-bottom: 16px;
  }

  .mat-step-icon-content ion-icon { font-size: 1em; }

  .mat-step-header .mat-step-icon-state-error {
    background-color: pal.color(pal.$error-red, 500);
    color: white;
  }
}

@mixin -color($config) {
  // Empty
}

@mixin -typography($config) {
  // Empty
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include -structure;

  @if $color {
    @include -color($color);
  }

  @if $typography {
    @include -typography($typography);
  } @else {
    @error "No typography for styling Material steppers is provided";
  }
}
