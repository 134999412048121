@use "../palettes";

a {
  color:
    palettes.color(
      palettes.$blue,
      500
    );
}

a:link {
  text-decoration: inherit;
}

/**
* a tags cannot be disabled with an attribute
* (see https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-a-element)
* so use a class instead.
*/
a.disabled {
  color:
    palettes.color(
      palettes.$gray,
      300
    );

  pointer-events: none;
}

a:visited {
  color:
    palettes.color(
      palettes.$blue,
      700
    );
}

a:hover {
  color:
    palettes.color(
      palettes.$blue,
      400
    );
  text-decoration: underline;
}

a.mat-button-base,
a.mat-button-base:active:active {
  color: unset;
  text-decoration: inherit;
}

/**
* Increased specificity to ensure a:visited has
* lower specificity than this rule and to have it forcibly
* provided before this rule (thanks to stylelint-no-descending-specificity).
* Otherwise a visited active link is not styled with this rule.
*/
a:active:active {
  color:
    palettes.color(
      palettes.$blue,
      600
    );
}
