/* You can add global styles to this file, and also import other style files */

@use "theme/theme-definitions" as themes;
@use "theme/palettes";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .absolutely-centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

$primary-color: themes.get-color-from-theme(themes.$default-theme);
$warn-color: themes.get-color-from-theme(
  themes.$default-theme,
  warn
);
$primary-text-color: themes.get-color-from-theme(
  themes.$default-theme,
  $color: default-contrast
);

html,
body {
  height: 100%;
  box-sizing: border-box;
  overflow: inherit;
  color: palettes.$text-dark;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

/* This for general global variables */
:root {
  --default-margin: 16px;
  --minimum-margin: 8px;

  --default-padding: 16px;
  --minimum-padding: 8px;

  --default-icon-size: 24px;

  --header-height: 100px;

  /* Custom colering of SAP elements */

  --sapButton_Selected_Background: #{$primary-color} !important;
  --sapButton_Active_Background: #{$primary-color} !important;
  --sapButton_Active_BorderColor: #{$primary-color} !important;
  --sapButton_Active_TextColor: #dddcdc !important;
  --sapContent_IconColor: #{$primary-color} !important;

  /* Status indications */

  --positive-status-light-color: rgb(203, 233, 203);
  --negative-status-light-color: rgb(228, 181, 204);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chilren-inline-block > * {
  display: inline-block;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.as-split-gutter.as-dragged > .as-split-gutter-icon {
  background: rgb(105, 105, 105);
}

body {
  margin: 0;
  overscroll-behavior: none;
}

.box-decoration-default {
  box-shadow: 0 0 3px -1px;
  border-radius: 5px;
}

.dismiss-all-notifications {
  margin-bottom: var(--minimum-margin);
  margin-left: auto;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
}

.dismiss-notification {
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
}

fieldset {
  border: none;
  box-shadow:
    0 2px 3px -1px #504f4fad,
    -1px 1px 2px -1px #00000026;
  border-radius: 4px;

  margin-bottom: var(--default-margin);
}

/**
* Based on https://gomakethings.com/hidden-content-for-better-a11y/#hiding-the-link
*/
.hidden.screen-reader-accessible {
  visibility: visible;

  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);

  height: 1px;
  width: 1px;

  margin: -1px;
  padding: 0;

  border: 0;

  overflow: hidden;
  white-space: nowrap;
}

ion-icon {
  pointer-events: none;
  font-size: var(--default-icon-size);
}

mat-form-field.no-underline .mat-form-field-underline {
  display: none;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

mat-expansion-panel.no-padding .mat-expansion-panel-body {
  padding: var(--minimum-padding);
}

mat-expansion-panel-header.mat-expansion-panel-header mat-panel-title {
  margin-bottom: 0;
}

.mat-expansion-indicator {
  padding-bottom: 4px;
}

.padding-default {
  padding: var(--default-padding);
}

.pseudo-anchor {
  color: $primary-color;
}

.pseudo-anchor:hover {
  cursor: pointer;
}

.series-type-toggle * {
  line-height: 30px !important;
}

.mat-badge-gray .mat-badge-content {
  background: rgb(127, 137, 145);
}

.margin-minimum {
  &:not(.bottom, .top, .left, .right, .top-bottom, .left-right) {
    margin: var(--minimum-margin);
  }

  &.bottom {
    margin-bottom: var(--minimum-margin);
  }

  &.right {
    margin-right: var(--minimum-margin);
  }

  &.top {
    margin-top: var(--minimum-margin);
  }
}

.margin-default:not(.bottom, .top, .left, .right, .top-bottom, .left-right) {
  margin: var(--minimum-default);
}

.margin-default.bottom {
  margin-bottom: var(--default-margin);
}

.margin-default.top {
  margin-top: var(--default-margin);
}

div.mat-tooltip {
  margin: 0;
}

// -- Process configuration component
div.process-configuration-component-context-menu .mat-menu-content {
  padding-top: 0;
  padding-bottom: 0;

  --deletion-color: #{$warn-color};

  & .delete {
    color: var(--deletion-color);

    & ion-icon {
      color: var(--deletion-color);
    }
  }
}

small,
.small {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}

small.extra-small {
  font-size: 11.11px;
  line-height: 16px;
  font-weight: 400;
}

.text-weight-extra-light {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

hr {
  background: palettes.color(palettes.$gray, 200);
}

// Quick'n'Dirty: Increase specificity so our rules don't overwrite this.
.cdk-visually-hidden.cdk-visually-hidden.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  appearance: none;
}

// remove arrows in numeric input fields
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

// Helper to ease alignment of button contents
.flex-button-wrapper {
  .mat-button-wrapper {
    display: flex;
  }
}

.mat-icon-button:hover {
  color: palettes.color(palettes.$gray, 600) !important;
}

.mat-icon-button:focus {
  color: palettes.color(palettes.$gray, 900) !important;
}

.mat-icon-button:active {
  color: palettes.color(palettes.$blue, 500) !important;
}

.basic-label {
  font-size: 12px;
  color: #{palettes.color(palettes.$gray, 500)};
}

mat-form-field.removed-wrapper-padding .mat-form-field-wrapper {
  padding-bottom: 0;
}

.info-icon {
  color: palettes.color(palettes.$gray, 500);
  font-size: 1em;
  align-self: center;
  vertical-align: middle;
}

.full-width-dialog .mat-dialog-container {
  min-width: 80vw !important;
}

/**
* Styling applied to the reset buttons created
* by the AutoResetZoomButtonsDirective
*/
.chart-zoom-reset-button button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;

  color: palettes.color(palettes.$blue, 500);
  font-size: 12px;
  line-height: 20px;

  &:hover {
    color: palettes.color(palettes.$blue, 300) !important;
  }

  &:active {
    color: palettes.color(palettes.$blue, 600) !important;
  }
}

.chart-zoom-row-highlight {
  stroke: palettes.color(palettes.$blue, 500);
  fill: transparent;
  stroke-dasharray: 4px 8px;
  stroke-width: 1px;
}

.userSelectMatSelect {
  max-width: none !important;
  background: white !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden !important;
  max-height: var(--userSelectHeight, 50vh) !important;
}

.overlayPaneShift {
  transform: translateX(-16px) !important;
}
