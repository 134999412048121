@use "@angular/material" as mat;
@use "../palettes" as pal;
@use "../typography" as typo;

@mixin -structure {
  mat-card.mat-card {
    min-width: 70px;
    padding: 24px;

    &.slim {
      padding: 16px;
    }

    border-radius: 0 0 4px 4px;

    overflow: hidden;
    overflow-y: auto;
  }

  .mat-card .mat-card-header {
    & .mat-card-title {
      margin-block-end: 16px;
    }

    & .mat-card-subtitle {
      margin-block-end: 16px;
    }

    & .mat-card-header-text {
      margin: 0;
    }
  }
}

@mixin -color($config) {
  mat-card {
    border:
      1px solid pal.color(
        pal.$gray,
        100
      );
  }
}

@mixin -typography($config) {
  .mat-card-header .mat-card-title {
    @include mat.typography-level($config, title);
  }

  .mat-card-header .mat-card-subtitle {
    @include mat.typography-level($config, subheading-2);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include -structure;

  @if $color-config {
    @include -color($color-config);
  }

  @if $typography {
    @include -typography($typography);
  } @else {
    @error "No typography provided to card theme";
  }
}
