@use "@angular/material" as mat;
@use "/src/theme/typography" as typo;

@mixin -typography($config) {
  .mat-typography h6,
  .mat-h6 {
    font: 18px/24px typo.font-family(typo.$typography);
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @if $typography {
    @include -typography($typography);
  }
}
