@use "sass:map";
@use "sass:color";
@use "@angular/material" as mat;
@use "../typography" as typo;
@use "../palettes";

@mixin -structure {
  mat-header-row,
  table tr.mat-header-row {
    height: 64px;
    background: palettes.color(palettes.$gray, 100);
  }

  mat-row,
  table tr.mat-row {
    height: 42px;
    min-height: 42px;
  }

  /**
  * Note: looks ugly, but increases specificity to be
  * 1 class-level higher than internal styles.
  */
  tr th.mat-header-cell.mat-header-cell,
  tr td.mat-cell.mat-cell,
  tr td.mat-footer-cell.mat-footer-cell,
  mat-header-cell.mat-header-cell.mat-header-cell,
  mat-cell.mat-cell.mat-cell,
  mat-footer-cell.mat-footer-cell.mat-footer-cell {
    padding: 8px 16px;
  }

  mat-cell.mat-cell.mat-cell:has(mat-table) {
    padding: 0 0 0 16px;
  }

  th {
    vertical-align: top;
  }

  table {
    // there is a weird bug in firefox where border-collapse: collapse does not play nicely
    // with a background color for the mat-header-row, leading to vanishing borders around
    // the header row. Choosing border-collapse: separate did not change the appearance in
    // edge, and solves the problem in firefox.
    // Though, logically, `collapse` is the option that would make more sense.
    border-collapse: separate;
  }

  mat-table.mat-table,
  table.mat-table {
    border-radius: 4px;
    overflow: hidden;
  }
}

@mixin -color($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-table {
    background: palettes.color($background, "card");

    // border: 1px solid color.change(palettes.color($foreground, divider), $alpha: 1, $lightness: 88%);
  }

  .mat-table thead,
  .mat-table tbody,
  .mat-table tfoot,
  mat-row,
  mat-footer-row,
  [mat-row],
  [mat-footer-row],
  .mat-table-sticky {
    background: inherit;
  }

  mat-header-row,
  [mat-header-row],
  .mat-table-sticky {
    background: palettes.color(palettes.$gray, 100);
  }

  mat-row.mat-row,
  mat-header-row.mat-header-row,
  mat-footer-row.mat-footer-row,
  th.mat-header-cell.mat-header-cell,
  td.mat-cell.mat-cell,
  td.mat-footer-cell.mat-footer-cell {
    // border-bottom-color: color.change(palettes.color($foreground, divider), $alpha: 1, $lightness: 88%);
    border-bottom: 0;
  }

  .mat-header-cell {
    color: palettes.color($foreground, secondary-text);
  }

  .mat-cell,
  .mat-footer-cell {
    color: palettes.color($foreground, text);
  }

  .mat-row:nth-of-type(even) {
    background: palettes.color(palettes.$blue, 50);
  }
}

@mixin -typography($config) {
  .mat-table {
    font-family: mat.font-family($config);
  }

  .mat-header-cell {
    @include mat.typography-level($config, body-2);
  }

  .mat-cell,
  .mat-footer-cell {
    @include mat.typography-level($config, body-1);
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include -structure;

  @if $color {
    @include -color($color);
  }

  @if $typography {
    @include -typography($typography);
  }
}
