@use "sass:map";
@use "@angular/material" as mat;
@use "../palettes";
@use "../shadows";
@use "../typography" as typo;

@mixin -structure {
  snack-bar-container.mat-snack-bar-container {
    min-width: 200px;
    max-width: 640px;

    padding: 16px;

    border-radius: 4px;
  }

  simple-snack-bar .mat-simple-snackbar-action button {
    max-height: unset;
  }
}

@mixin -color($config) {
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  $background: palettes.color($primary, 700);
  $text: mat.get-contrast-color-from-palette($primary, 700);

  $action-text: palettes.color($accent, 300);

  snack-bar-container.mat-snack-bar-container {
    // Use the primary text on the dark theme, even though the lighter one uses
    // a secondary, because the contrast on the light primary text is poor.
    background: $background;
    color: $text;

    @include shadows.elevation(6);
  }

  .mat-simple-snackbar-action {
    color: $action-text;
  }
}

@mixin -typography($config) {
  simple-snack-bar.mat-simple-snackbar {
    @include mat.typography-level($config, body-1);
  }

  .mat-simple-snackbar-action {
    @include mat.typography-level($config, small);
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include -structure;

  @if $color {
    @include -color($color);
  }

  @if $typography {
    @include -typography($typography);
  } @else {
    @error "No typography for styling snack bars is provided";
  }
}
