@use "sass:map";

/**
* (Stepped) elevations and their box-shadow values.
*/
$elevations: (
  0: 0 0 0 rgba(0, 0, 0, 25%),
  1: 0 1px 1px rgba(0, 0, 0, 20%),
  2: 0 2px 2px rgba(0, 0, 0, 20%),
  3: 0 3px 3px rgba(0, 0, 0, 20%),
  4: 0 4px 4px rgba(0, 0, 0, 20%),
  6: 0 6px 6px rgba(0, 0, 0, 20%),
  8: 0 8px 8px rgba(0, 0, 0, 20%),
  12: 0 12px 12px rgba(0, 0, 0, 20%),
  16: 0 16px 16px rgba(0, 0, 0, 20%),
  24: 0 24px 24px rgba(0, 0, 0, 20%),
  // Insets (negative)
  -4: 0 -2px 4px 0 #00000040 inset,
  // colored
  blue: 0 2px 2px #b3dcf5
);

@mixin box-shadow($level) {
  box-shadow: map.get($elevations, $level);
}

@mixin elevation($level) {
  @include box-shadow($level);
}
