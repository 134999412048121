@use "../palettes";
@use "../shadows";

@mixin color($primary, $neutral) {
  .mat-ripple-element {
    display: none;
  }

  // Default
  .mat-slide-toggle-thumb {
    @include shadows.box-shadow(1);
    background: palettes.color($neutral, 50);
  }

  .mat-slide-toggle-bar {
    background: palettes.color($neutral, 200);
  }

  // Pressed
  .mat-slide-toggle-thumb:active {
    background: palettes.color($neutral, 500);

    @include shadows.box-shadow(0);
  }

  // Hover
  .mat-slide-toggle-thumb:hover {
    background: palettes.color($neutral, 400);
  }

  &.mat-checked .mat-slide-toggle-thumb {
    @include shadows.box-shadow(0);
    background: palettes.color($primary, 500);
  }

  .mat-slide-toggle-bar:active .mat-slide-toggle-thumb {
    background: palettes.color($neutral, 500);

    @include shadows.box-shadow(0);
  }

  .mat-slide-toggle-bar:hover .mat-slide-toggle-thumb {
    background: palettes.color($neutral, 400);
  }

  // Checked
  &.mat-checked {
    .mat-slide-toggle-bar {
      background: palettes.color($primary, 200);
    }

    .mat-slide-toggle-thumb:hover {
      background: palettes.color($primary, 300);

      @include shadows.box-shadow(1);
    }

    .mat-slide-toggle-thumb:active {
      background: palettes.color($primary, 400);

      @include shadows.box-shadow(0);
    }

    // Hover
    .mat-slide-toggle-bar:hover .mat-slide-toggle-thumb {
      background: palettes.color($primary, 300);

      @include shadows.box-shadow(1);
    }

    // Pressed
    .mat-slide-toggle-bar:active .mat-slide-toggle-thumb {
      background: palettes.color($primary, 400);

      @include shadows.box-shadow(0);
    }
  }
}

mat-slide-toggle.mat-slide-toggle.mat-primary {
  @include color(palettes.$indigo, palettes.$gray);
}

mat-slide-toggle.mat-slide-toggle.mat-accent {
  @include color(palettes.$indigo, palettes.$gray);
}

mat-slide-toggle.mat-slide-toggle.mat-warn {
  @include color(palettes.$error-red, palettes.$gray);
}
