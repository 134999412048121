@use "../palettes";
@use "sass:string";

@mixin color($palette) {
  /** Animation adjustments **/
  @keyframes #{"unchecked-checked-anim--" + string.slice(#{palettes.color($palette, 400)}, 2)} {
    0% {
      background-color: palettes.color($palette, 400);
    }

    50% {
      background-color: palettes.color($palette, 400);
    }
  }

  &.mat-checkbox-anim {
    &-unchecked-checked {
      .mat-checkbox-background {
        // Somehow without this, the transition is not smooth and it blinks.
        animation: 100ms linear 0ms #{"unchecked-checked-anim--" + string.slice(#{palettes.color($palette, 400)}, 2)};
      }
    }
  }

  // Default
  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    border-radius: 2px;

    .mat-checkbox-ripple {
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
    }

    // Normal
    .mat-checkbox-frame {
      width: 20px;
      height: 20px;

      border: 1px solid palettes.color(palettes.$gray, 500);
    }

    .mat-checkbox-persistent-ripple {
      border-radius: 2px;

      background-color: palettes.color($palette, 400);
    }

    // Hover
    &:hover {
      .mat-checkbox-frame {
        border: 1px solid palettes.color($palette, 400);
      }

      .mat-checkbox-persistent-ripple {
        opacity: 0.2;
      }
    }

    // Pressed
    &:active {
      .mat-checkbox-frame {
        border: 1px solid palettes.color($palette, 500);
      }

      .mat-checkbox-persistent-ripple {
        background-color: palettes.color($palette, 200);
        border: 1px solid palettes.color($palette, 500);
        opacity: 1;
      }
    }
  }

  // disabled
  &.mat-checkbox-disabled {
    .mat-checkbox-inner-container {
      .mat-checkbox-persistent-ripple {
        background: none;
        border: none;
      }

      .mat-checkbox-frame {
        border: 1px solid rgba(palettes.color(palettes.$gray, 500), 0.25);
      }
    }

    .mat-checkbox-checked .mat-checkbox-inner-container .mat-checkbox-background {
      background-color: rgba(palettes.color(palettes.$gray, 500), 0.25);
    }
  }

  // Checked
  &.mat-checkbox-checked:not(.mat-checkbox-disabled) .mat-checkbox-inner-container {
    // Normal
    .mat-checkbox-background {
      background-color: palettes.color($palette, 400);
    }

    // Hover
    &:hover .mat-checkbox-background {
      background-color: palettes.color($palette, 300);
    }

    // Pressed
    &:active {
      .mat-checkbox-frame {
        border: 1px solid palettes.color($palette, 400);
      }

      .mat-checkbox-persistent-ripple {
        opacity: 0;
      }

      .mat-checkbox-background {
        background-color: palettes.color($palette, 400);
        opacity: 1;
      }
    }
  }
}

mat-checkbox.mat-checkbox {
  @include color(palettes.$indigo);
}
