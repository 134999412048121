@use "../palettes";
@use "../typography" as typo;
@use "../shadows" as shadows;
@use "@angular/material/legacy-button/button-base" as matbutton with (
  $border-radius: 4px,
  $padding: 8px 16px,
  $min-width: 64px,
  $line-height: 24px
);

button,
a {
  &.mat-button,
  &.mat-flat-button {
    @include matbutton.base;

    border: 1px solid transparent;
  }

  &.mat-button {
    color: palettes.color(palettes.$gray, 500);
  }

  &.mat-button-disabled {
    color: #{palettes.color(palettes.$gray, 300)};
  }

  &.mat-button-disabled:hover {
    color: #{palettes.color(palettes.$gray, 300)} !important;
  }

  &.mat-button-disabled:active {
    color: #{palettes.color(palettes.$gray, 300)} !important;
  }

  &.mat-button-disabled:focus {
    color: #{palettes.color(palettes.$gray, 300)} !important;
  }

  &.mat-raised-button {
    @include matbutton.raised-button;

    border: 1px solid transparent;
  }

  @mixin color($palette) {
    &.mat-button,
    &.mat-flat-button {
      @include matbutton.base;

      border: 1px solid transparent;
    }

    &.mat-raised-button {
      @include matbutton.raised-button;

      border: 1px solid transparent;
    }

    &:not(.mat-button-disabled) {
      .mat-button-focus-overlay {
        background-color: palettes.color($palette, 500);
      }

      &.mat-flat-button,
      &.mat-raised-button {
        color: palettes.get-contrast-color-from-palette($palette, 500);
        background-color: palettes.color($palette, 500);

        &:hover {
          background-color: palettes.color($palette, 400);
          border: 1px solid palettes.color($palette, 400);

          @include shadows.box-shadow(2);
        }

        &:active {
          background-color: palettes.color($palette, 600);
          border: 1px solid palettes.color($palette, 600);

          @include shadows.box-shadow(-4);
        }
      }

      &.mat-button {
        /** colors **/
        color: palettes.color($palette, 500);

        // Hover
        &:hover {
          color: palettes.get-color-from-palette($palette, 300);
        }

        // Pressed
        &:active {
          color: palettes.get-color-from-palette($palette, 600);
        }
      }

      &.mat-stroked-button {
        color: palettes.color($palette, 500);
        border-color: palettes.color($palette, 500);

        &:hover {
          @include shadows.box-shadow(2);

          .mat-button-focus-overlay {
            opacity: 0.1;
          }
        }

        &:active {
          @include shadows.box-shadow(-4);
        }
      }
    }

    &.mat-button-disabled {
      color: palettes.color(palettes.$gray, 300) !important;
      background-color: palettes.color(palettes.$gray, 200) !important;
    }
  }

  // We don't need the focus overlay for text-only buttons
  & .mat-button-focus-overlay {
    transition: unset !important;
    opacity: 0 !important;
  }

  &.mat-icon-button {
    color: palettes.color(palettes.$gray, 800);

    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  /* stylelint-disable-next-line -- Long selector */
  &:not(.mat-primary):not(.mat-accent):not(.mat-warn):not(.mat-alert):not(.mat-error):not(.mat-success):not(.mat-button-toggle-button):not(a:not(.mat-button-base)):not(.mat-menu-item) {
    &:active {
      color: palettes.color(palettes.$gray, 600);
    }

    &:hover:not(:active) {
      color: palettes.color(palettes.$gray, 300);
    }
  }

  &.mat-primary {
    @include color(palettes.$blue);
  }

  &.mat-accent {
    @include color(palettes.$gray);

    &.mat-flat-button,
    &.mat-raised-button {
      &:not(&:hover, &:active) {
        background-color: palettes.color(palettes.$gray, 50);
        color: palettes.color(palettes.$gray, 500);
      }
      border: 1px solid palettes.color(palettes.$gray, 500);
    }
  }

  &.mat-warn {
    @include color(palettes.$error-red);
  }

  &.mat-error {
    @include color(palettes.$error-red);
  }

  &.mat-alert {
    @include color(palettes.$alert-yellow);
  }

  &.mat-success {
    @include color(palettes.$success-green);
  }

  /** default color for stroked buttons is neutral */
  &.mat-stroked-button {
    @include color(palettes.$gray);
  }

  /** Size adjustments **/
  &.mat-button,
  &.mat-flat-button,
  &.mat-raised-button,
  &.mat-stroked-button {
    border-radius: 4px;
    line-height: 24px;
    padding: 8px 16px;

    &.large {
      border-radius: 4px;
      padding: 16px 24px;
      font: {
        size: typo.font-size(typo.$typography, subheading-1);
        weight: typo.font-weight(typo.$typography, subheading-1);
      }

      &:hover {
        @include shadows.box-shadow(4);
      }
    }

    &.small {
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 13.3px;
    }

    &.xs {
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
