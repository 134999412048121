@use "@angular/material" as mat;

/* Core theme module */
@use "@angular/material/core/core-theme";
@use "@angular/material/core/selection/pseudo-checkbox/pseudo-checkbox-theme";

/* Component theme modules */
@use "@angular/material/legacy-autocomplete/autocomplete-theme";
@use "@angular/material/badge/badge-theme";
@use "@angular/material/bottom-sheet/bottom-sheet-theme";
@use "@angular/material/legacy-button/button-theme";
@use "@angular/material/button-toggle/button-toggle-theme";
@use "@angular/material/legacy-checkbox/checkbox-theme";
@use "@angular/material/legacy-chips/chips-theme";
@use "@angular/material/datepicker/datepicker-theme";
@use "@angular/material/divider/divider-theme";
@use "@angular/material/grid-list/grid-list-theme";
@use "@angular/material/icon/icon-theme";
@use "@angular/material/legacy-input/input-theme";
@use "@angular/material/legacy-list/list-theme";
@use "@angular/material/legacy-menu/menu-theme";
@use "@angular/material/legacy-paginator/paginator-theme";
@use "@angular/material/legacy-progress-bar/progress-bar-theme";
@use "@angular/material/legacy-progress-spinner/progress-spinner-theme";
@use "@angular/material/legacy-radio/radio-theme";
@use "@angular/material/legacy-select/select-theme";
@use "@angular/material/sidenav/sidenav-theme";
@use "@angular/material/legacy-slide-toggle/slide-toggle-theme";
@use "@angular/material/legacy-slider/slider-theme";
@use "@angular/material/stepper/stepper-theme";
@use "@angular/material/sort/sort-theme";
@use "@angular/material/legacy-tabs/tabs-theme";
@use "@angular/material/toolbar/toolbar-theme";
@use "@angular/material/legacy-tooltip/tooltip-theme";
@use "@angular/material/tree/tree-theme";
@use "@angular/material/legacy-form-field/form-field-theme";
@use "@angular/material/core/theming/theming";

/* Our custom theme base modules */
@use "theme/typography";
@use "theme/theme-definitions";
@use "theme/palettes";

/* add here all the scss files from the theme/components/ folder */
@use "theme/components/anchor";
@use "theme/components/banner";
@use "theme/components/button";
@use "theme/components/button-toggle";
@use "theme/components/card";
@use "theme/components/checkbox";
@use "theme/components/chips";
@use "theme/components/dialog";
@use "theme/components/expansion";
@use "theme/components/headings";
@use "theme/components/input";
@use "theme/components/menu";
@use "theme/components/radio";
@use "theme/components/select";
@use "theme/components/slide-toggle";
@use "theme/components/snackbar";
@use "theme/components/stepper" as stepper-extension;
@use "theme/components/table";
@use "theme/components/tabs" as tabs-extension;

// Matero extensions
@use "@ng-matero/extensions" as mtx;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(typography.$typography);`
@include mat.all-legacy-component-typographies(typography.$typography);
@include mat.legacy-core;
@include typography.section-headings(typography.$typography);
@include typography.typography-classes;

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$default-theme: theme-definitions.$default-theme;
$indigo-theme: theme-definitions.$indigo-theme;

/* Mixins for component styles */
@include core-theme.theme($default-theme);
@include pseudo-checkbox-theme.theme($indigo-theme);
@include autocomplete-theme.theme($default-theme);
@include badge-theme.theme($default-theme);
@include bottom-sheet-theme.theme($default-theme);
@include button-theme.theme(theme-definitions.$button-theme);
@include button-toggle-theme.theme($default-theme);
@include card.theme($default-theme);
@include checkbox-theme.theme($indigo-theme);
@include chips-theme.theme($indigo-theme);
@include datepicker-theme.theme($default-theme);
@include dialog.theme($default-theme);
@include divider-theme.theme($default-theme);
@include expansion.theme($default-theme);
@include form-field-theme.theme($default-theme);
@include grid-list-theme.theme($default-theme);
@include headings.theme($default-theme);
@include icon-theme.theme($default-theme);
@include input-theme.theme($default-theme);
@include list-theme.theme($default-theme);
@include menu-theme.theme($default-theme);
@include paginator-theme.theme($default-theme);
@include progress-bar-theme.theme($default-theme);
@include progress-spinner-theme.theme($default-theme);
@include radio-theme.theme($indigo-theme);
@include select-theme.theme($default-theme);
@include sidenav-theme.theme($default-theme);
@include slide-toggle-theme.theme($indigo-theme);
@include slider-theme.theme($default-theme);
@include snackbar.theme(theme-definitions.$snackbar-theme);
@include stepper-theme.theme($default-theme);
@include stepper-extension.theme($default-theme);
@include sort-theme.theme($default-theme);
@include table.theme($default-theme);
@include tabs-theme.theme($default-theme);
@include tabs-extension.theme($default-theme);
@include toolbar-theme.theme($default-theme);
@include tooltip-theme.theme($default-theme);
@include tree-theme.theme($default-theme);

// Matero extensions
@include mtx.all-component-themes($default-theme);
