@use 'sass:color';
@use 'sass:map';
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/style/sass-utils';
@use '../core/tokens/token-utils';
@use '../core/tokens/m2/mtx/colorpicker' as tokens-mtx-colorpicker;

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: map.get($config, background);

  @include sass-utils.current-selector-or-root() {
    @include token-utils.create-token-values(tokens-mtx-colorpicker.$prefix,
      tokens-mtx-colorpicker.get-color-tokens($config));
  }

  .mtx-colorpicker-toggle-active {
    &.mat-accent {
      $accent-tokens: tokens-mtx-colorpicker.private-get-toggle-color-palette-color-tokens($config, accent);
      @include token-utils.create-token-values(tokens-mtx-colorpicker.$prefix, $accent-tokens);
    }

    &.mat-warn {
      $warn-tokens: tokens-mtx-colorpicker.private-get-toggle-color-palette-color-tokens($config, warn);
      @include token-utils.create-token-values(tokens-mtx-colorpicker.$prefix, $warn-tokens);
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);

  @include theming.private-check-duplicate-theme-styles($theme, 'mtx-colorpicker') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color !=null {
      @include color($color);
    }

    @if $density !=null {
      @include density($density);
    }

    @if $typography !=null {
      @include typography($typography);
    }
  }
}
