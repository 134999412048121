@use "sass:map";
@use "@angular/material" as mat;
@use "../typography" as typo;
@use "../shadows";
@use "../palettes";

@mixin private-structure {
  mat-dialog-container.mat-dialog-container {
    position: relative;
    border-radius: 4px;
    padding: 16px;

    min-width: 500px;
    max-width: 860px;

    max-height: calc(100vh - 40px);

    overflow: hidden;

    overflow-y: auto;
  }

  mat-dialog-container .mat-dialog-actions {
    margin-bottom: -16px;
    justify-content: end;
  }

  mat-dialog-container .mat-dialog-content {
    margin: 0;
    padding: 0;
  }

  mat-dialog-container .mat-dialog-title {
    margin-bottom: 16px;
  }

  mat-dialog-actions {
    justify-content: end;
  }

  /**
   *  Used to wrap [mat-dialog-title] and [mat-dialog-close]
   */
  .mat-dialog-container .title-close-container-flex {
    display: flex;
    flex-flow: row nowrap;

    & [mat-dialog-close] {
      margin-inline-start: auto;

      display: block;
      height: 20px;
      width: 24px;

      content: "";
      background: url("/assets/custom-icons/close-outline.svg");

      cursor: pointer;
    }
  }

  .fullscreen-dialog {
    mat-dialog-container {
      max-width: 100vw;
      display: flex;
      flex-direction: column;

      & > * {
        flex: 1;
      }
    }
  }
}

@mixin private-color($config) {
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-dialog-container {
    @include shadows.elevation(16);
    background: palettes.color($background, dialog);

    // background: red;
    color: palettes.color($foreground, text);
  }
}

@mixin private-typography($config) {
  .mat-typography {
    & [mat-dialog-title],
    .mat-dialog-title {
      @include mat.typography-level($config, subheading-1);
    }
  }
}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: if(
    mat.get-typography-config($theme),
    mat.get-typography-config($theme),
    typo.$typography
  );

  @include private-structure;

  @if $color {
    @include private-color($color);
  }

  @if $typography {
    @include private-typography($typography);
  } @else {
    @error "No typography for styling <mat-dialog> is provided";
  }
}
