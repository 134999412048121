@use "sass:map";
@use "../palettes";
@use "../shadows";
@use "@angular/material" as mat;
@use "../typography" as typo;

div.mat-select-panel-wrap {
  width: 0;
  min-width: inherit;
}

div.cdk-overlay-pane:has(.mat-select-panel-wrap) {
  pointer-events: none;
  transform: translateX(-16px) !important;

  // adjust the transform-origin in the case when it opens down, because we now
  // align it at the top exactly
  &[style~="bottom:"] .mat-select-panel {
    @include shadows.box-shadow(blue);
    transform-origin: 50% bottom 0 !important;
  }

  // style the case when the popup opens up instead of down, which is when
  // angular material puts "bottom" into the style of the panel
  &:not([style~="bottom:"]) .mat-select-panel {
    transform-origin: 50% top 0 !important;
    box-shadow: none;
  }
}

div.mat-autocomplete-panel {
  border: 1px solid palettes.color(palettes.$blue, 500);
  margin-top: 5px;
  border-radius: 2px;
}

div.mat-select-panel {
  width: fit-content;

  pointer-events: all;

  margin-top: 34px;
  margin-bottom: 34px;
  background: transparent;
  border-radius: 2px;

  // 1.9px is weirdly needed in Chrome/Edge instead of 2px
  border: 1.9px solid palettes.color(palettes.$blue, 500);
  margin-left: 4px;
  min-width: calc(100% + 24px) !important;
  max-width: 45vw;
}

div.mat-select-panel .mat-option {
  height: 40px;
  line-height: 40px;

  .mat-option-text {
    @include mat.typography-level(typo.$typography, small);
    line-height: inherit;
    border-bottom: 0.5px solid palettes.color(palettes.$gray, 100);
    margin-bottom: 1px;
  }

  &:last-child .mat-option-text {
    border-bottom: 0;
  }

  background: white;

  &.mat-active {
    background: palettes.color(palettes.$blue, 300);

    .mat-option-text {
      border-bottom: 0;
    }
  }

  &.mat-selected:not(.mat-option-multiple) {
    background: palettes.color(palettes.$gray, 100);

    .mat-option-text {
      border-bottom: 0;
    }
  }

  &.mat-option-disabled {
    color: palettes.color(palettes.$gray, 100);
  }

  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled) {
    background: palettes.color(palettes.$blue, 100);

    .mat-option-text {
      border-bottom: 0;
    }
  }
}

div.mat-select-arrow,
mat-form-field.mat-form-field.mat-focused div.mat-select-arrow {
  width: 0.625em;
  height: 0.625em;
  border-left: 3px solid;
  border-top: 3px solid;
  border-right: 0;
  transform: rotate(225deg);
  border-top-left-radius: 0.1875em;
}

div.mat-select-trigger {
  line-height: 1.3em;
  height: 1.3em;
}
