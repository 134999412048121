@use "../palettes";
@use "../shadows";

mat-chip.mat-chip.mat-standard-chip {
  /** layout changes **/
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  min-height: 20px;
  font-size: 12px;
  font-weight: 600;
  background: transparent;
  padding: 0 8px;

  &:not(.mat-chip-disabled),
  &.mat-chip-selected.mat-primary,
  &.mat-chip-selected.mat-accent,
  &.mat-chip-selected.mat-warn {
    .mat-chip-remove {
      opacity: 1;
      margin-right: -2px;

      &:hover {
        opacity: 1;
      }
    }
  }

  &::after {
    display: none;
  }

  .mat-chip-ripple {
    display: none;
  }

  & .mat-chip-remove.mat-chip-remove {
    margin-right: -2px;
    margin-left: 4px;
    height: 20px;
    width: 20px;
  }

  ion-icon {
    font-size: 20px;
  }

  /** color changes **/
  @mixin chip-color-palette($palette) {
    @include chip-color(
      palettes.color($palette, 100),
      palettes.color($palette, 300),
      palettes.color($palette, 500)
    );
  }

  @mixin chip-color($color-100, $color-300, $color-500) {
    // Default
    & {
      border-color: $color-300;
      color: $color-300;
    }

    // Hover/Pressed
    &:hover,
    &:focus {
      background-color: $color-100;
    }

    & .mat-chip-remove.mat-chip-remove {
      color: $color-300;
    }

    // Selected
    &.mat-chip-selected:not(.mat-chip-disabled) {
      // Default
      background-color: $color-100;
      color: $color-300;

      &:not(&:hover, &:focus) {
        border-color: $color-100;
      }

      // Remove button
      &.mat-chip-remove {
        color: $color-300;

        &:hover {
          color: $color-500;
        }
      }
    }
  }

  &.mat-primary {
    @include chip-color-palette(palettes.$indigo);
  }

  &.mat-accent {
    @include chip-color-palette(palettes.$orange);
  }

  &.mat-warn {
    @include chip-color-palette(palettes.$error-red);
  }

  &.color-azure {
    @include chip-color(
      palettes.color(palettes.$azure, 100),
      palettes.color(palettes.$azure, 200),
      palettes.color(palettes.$azure, 400)
    );
  }

  &.color-blue {
    @include chip-color(
      palettes.color(palettes.$blue, 100),
      palettes.color(palettes.$blue, 200),
      palettes.color(palettes.$blue, 400)
    );
  }

  &.color-orange {
    @include chip-color(
      palettes.color(palettes.$orange, 200),
      palettes.color(palettes.$orange, 500),
      palettes.color(palettes.$orange, 700)
    );
  }

  &.color-green {
    @include chip-color(
      palettes.color(palettes.$success-green, 100),
      palettes.color(palettes.$success-green, 200),
      palettes.color(palettes.$success-green, 400)
    );
  }

  &.color-gray {
    $palette: palettes.$gray;

    @include chip-color(
      palettes.color($palette, 50),
      palettes.color($palette, 50),
      palettes.color($palette, 100)
    );
  }

  &.color-yellow {
    @include chip-color-palette(palettes.$alert-yellow);
  }
}

mat-chip-list {
  // Remove hover effects on mat-chip when selection is not required
  .disable-click:hover {
    background-color: initial !important;
  }

  .disable-click:active {
    background-color: initial !important;
    box-shadow: none !important;
  }

  .disable-click:focus {
    background-color: initial !important;
  }
}
